const $ = jQuery;

export default class Scores
{
	constructor(element)
	{
		let app = window.nuable;
		
		this.$element = element;
		
		if(app.mode == App.MODE_HOMEOWNER)
			this.setSourceProperties(Scores.MAP_HOMEOWNER);
		else
			this.setSourceProperties(Scores.MAP_DEVELOPER);
		
		Scores.instances.push(this);
	}
	
	setSourceProperties(map)
	{
		let needsUpdate = false;
		
		this.$element.find("[data-property][data-type]").each((index, el) => {
			
			let type = $(el).attr("data-type");
			
			if(!map[type])
				return;
			
			$(el).attr("data-property", map[type]);
			needsUpdate = true;
			
		});
		
		if(needsUpdate)
			this.update();
	}
	
	populate(properties)
	{
		this._properties = properties;
		
		this.$element.find(".score[data-property]").each((index, el) => {
			
			let property	= $(el).attr("data-property");
			let value		= properties[property];
			let slider		= $(el).next(".slider");
			
			$(el).text(value);
			$(slider).css({
				width: Math.round( (value / 5) * 100 ) + "%"
			});
			
		});
	}
	
	update()
	{
		if(!this._properties)
			return;
		
		this.populate(this._properties);
	}
}

Scores.setSourceProperties = function(map)
{
	Scores.instances.forEach(instance => {
		instance.setSourceProperties(map);
	});
}

Scores.instances = [];

Scores.MAP_DEVELOPER = {
	"wind":			"LSW_Rank",
	"solar":		"LSS_Rank",
	"geo":			"GTB_GSRank",
	"hydro":		"SSH_HRank"
};

Scores.MAP_HOMEOWNER = {
	"wind":			"MSW_Rank",
	"solar":		"RMS_Rank",
	"geo":			"GTB_GSRank",
	"hydro":		"SSH_HRank"
};