import GestureHandling from '@geolonia/mbgl-gesture-handling';

import Info from "./Info.js";
import Popup from "./Popup.js";
import UI from "./UI.js";
import Detail from "./Detail.js";
import Tilemap from "./Tilemap.js";
import ZoomControl from "./ZoomControl.js";
import MainMenu from "./MainMenu.js";
import State from "./State.js";
import Intro from "./Intro.js";

export default class App
{
	constructor()
	{
		this.$element = $("body");
	}
	
	get selectedGridId()
	{
		return this._selectedGridId;
	}
	
	get mode()
	{
		return $("input[name='homeowner']").prop("checked") ? App.MODE_HOMEOWNER : App.MODE_DEVELOPER;
	}
	
	init()
	{
		// NB: Move some of these out of init functions, they're one-liners
		this.initMap();
		this.initInfo();
		this.initUI();
		this.initDetail();
	}
	
	initMap()
	{
		mapboxgl.accessToken = App.MAPBOX_ACCESS_TOKEN;
		
		let bboxPadding = 0.15;
		
		// Initialise modules
		this.map = new mapboxgl.Map({
			container:		"map",
			style:			App.MAPBOX_STYLE_URL,
			center:			App.DEFAULT_MAP_CENTER,
			zoom:			App.DEFAULT_MAP_ZOOM,
			minZoom:		12,
			zoomControl:	false,
			maxBounds:		[
				App.BBOX_WALES[0] - bboxPadding,
				App.BBOX_WALES[1] - bboxPadding,
				App.BBOX_WALES[2] + bboxPadding,
				App.BBOX_WALES[3] + bboxPadding
			]
		});
		
		// Greedy gesture handling on touch devices
		if("ontouchstart" in window)
		{
			// Defaults
			const options = {
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				textColor: '#ffffff',
				textMessage: 'Use alt + scroll to zoom the map.',
				textMessageMobile: 'Use two fingers to move the map.',
				timeout: 3000,
				modifierKey: 'alt'
			};

			this.gestureHandling = new GestureHandling(options).addTo(this.map);
		}
		
		// Controls
		this.geocoder = new MapboxGeocoder({
			accessToken:	App.MAPBOX_ACCESS_TOKEN,
			mapboxgl:		mapboxgl,
			bbox:			App.BBOX_WALES
		});
		
		this.navigation = new mapboxgl.NavigationControl();
		this.geolocation = new mapboxgl.GeolocateControl(
			{
				positionOptions: {
					enableHighAccuracy: true
				},
				trackUserLocation: true
			}
		);
		
		this.zoomControl = new ZoomControl(this.map);
		$(".mapboxgl-ctrl-bottom-right").prepend(this.zoomControl.$element);
		
		// User interface
		this.map.addControl(this.geocoder);
		this.map.addControl(this.navigation, "bottom-right");
		this.map.addControl(this.geolocation);
		
		// Event listeners
		this.map.on("load", event => this.onMapLoad(event));
		this.map.on("moveend", event => { this.$element.trigger("statechange.nuable") });
	}
	
	initInfo()
	{
		this.info = new Info(this);
	}
	
	initUI()
	{
		this.ui = new UI(this);
		this.mainMenu = new MainMenu();
	}
	
	initDetail()
	{
		this.detail = new Detail();
	}
	
	showInfo(properties, latlng)
	{
		this._selectedGridId = properties.GRID_ID;
		
		if(this.popup)
			this.popup.close();
		
		this.popup = new Popup();
		this.popup.populate(properties);
		this.popup.open(this.map, latlng);
		
		this.info.populate(properties, latlng);
		
		this.detail.populate(properties, latlng);
	}
	
	hideInfo()
	{
		delete this._selectedGridId;
	}
	
	getFeatureByGridId(id)
	{
		let features = this.map.querySourceFeatures(App.LAYER_NAME, {
			sourceLayer:	App.LAYER_NAME,
			filter:			['==', "GRID_ID", id]
		});
		
		if(!features.length)
			return null;
		
		return features[0];
	}
	
	getFeaturesAtLatLng(latlng)
	{
		let pixel		= this.map.project([latlng[1], latlng[0]]);
		let threshold	= 1;
		let bbox		= [
			[pixel.x - threshold, pixel.y - threshold],
			[pixel.x + threshold, pixel.y + threshold]
		];
		
		// NB: Find the actual features from our vector tiles.
		return this.map.queryRenderedFeatures(bbox, {
			layers: [App.LAYER_NAME]
		});
	}
	
	getFeatureCentroid(feature)
	{
		let point			= [0, 0];
		let coordinates		= feature.geometry.coordinates[0]; // NB: First in array, I guess multi-polygons are supported here. Just use the first for now
		
		coordinates.forEach(coord => {
			
			point[0] += coord[0];
			point[1] += coord[1];
			
		});
		
		point[0] /= coordinates.length;
		point[1] /= coordinates.length;
		
		return [point[1], point[0]]; // NB: Conversion from lnglat to latlng
	}
	
	getQueryStringParamValue(name, url)
	{
		if(!url)
			url = window.location.href;
		
		name = name.replace(/[\[\]]/g, '\\$&');
		
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
		
		if(!results)
			return null;
		
		if(!results[2])
			return '';
		
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}
	
	getMapFirstSymbolLayerId()
	{
		let layers = this.map.getStyle().layers;
		let firstSymbolLayerId;
		
		for (let i = 0; i < layers.length; i++)
		{
			if (layers[i].type === 'symbol')
			{
				firstSymbolLayerId = layers[i].id;
				break;
			}
		}
		
		return firstSymbolLayerId;
	}
	
	onMapLoad(event)
	{
		// NB: Fixes bad sizing - I believe this happens after renewable icons load in the menu
		this.map.resize();
		
		// Add our vector tiles source from custom Docker instance
		this.map.addSource(App.LAYER_NAME, {
			type:			"vector",
			url:			App.DOCKER_DATA_URL
		});
		
		// TODO: Going to need to add a presentation filter for wind,solar scores to the grid as a visible switching layer so users can see a heat map of scores for each renewable source.
		this.map.addLayer({
			id:				App.LAYER_NAME,
			type:			"fill",
			source:			App.LAYER_NAME,
			"source-layer":	App.LAYER_NAME,
			paint:			{
				'fill-outline-color': 'rgba(0, 0, 0, 0.1)',
				'fill-color': 'rgba(0, 0, 0, 0.01)'
			}
		}, this.getMapFirstSymbolLayerId());
		
		this.map.once("idle", event => this.onTileMapLoaded(event));
		
		// NB: Place polygon under these labels
		// TODO: 'settlement-label' in prototype source - what does this refer to? (index.html:238) - I believe this has been copied from the MapBox docs
		
		// Developer tile map
		this.tilemap = new Tilemap(this, this.map);
		
		// Add the source to query. In this example we're using
        // county polygons uploaded as vector tiles
	
		this.map.on("click", event => this.onMapClick(event));
		this.map.on("click", event => this.onFeatureClick(event));
		this.map.on("mouseenter", App.LAYER_NAME, event => this.onFeatureMouseEnter(event));
		this.map.on("mouseleave", App.LAYER_NAME, event => this.onFeatureMouseLeave(event));
	
		// Define GeoCoder results for popup
		this.geocoder.on("result", event => this.onGeocoderResult(event));
		
		if(!window.location.hash.length)
		{
			$("#preloader").fadeOut(500, function() { 
				$(this).remove(); 
				self.intro = new Intro();
			});
		}
	}
	
	onTileMapLoaded(event)
	{
		// NB: Postcode input from external form, have to wait until this point for the tilemap to be ready
		let postcode;
		
		if(postcode = this.getQueryStringParamValue("postcode"))
		{
			$(".mapboxgl-ctrl-geocoder--input").val(postcode);
			
			this.geocoder.query(postcode);
			
			window.location.hash = "";
			
			if(window.history)
			{
				let url = window.location.href.replace(/postcode=[A-Z0-9\s]+/i, "");
				
				history.replaceState({}, document.title, url);
			}
		}
		
		this.state = new State(this);
	}
	
	onMapClick(event)
	{
		this._selectedGridId = null;
		this.$element.trigger("statechange.nuable");
	}
	
	onFeatureClick(event)
	{
		let latlng		= [event.lngLat.lat, event.lngLat.lng];
		let features	= this.getFeaturesAtLatLng(latlng);
		
		if(features.length == 0)
		{
			// this.map.fire("closeAllPopups");
			return;
		}
		
		let feature		= features[0];
		let properties	= feature.properties;
		let point;
		
		if(this.map.getZoom() >= 15)
			point		= latlng;
		else
			point		= this.getFeatureCentroid(feature);
		
		this.showInfo(properties, point);
	}
	
	onFeatureMouseEnter(event)
	{
		// NB: Change the cursor to a pointer when the mouse is over the states layer.
		this.map.getCanvas().style.cursor = "pointer";
	}
	
	onFeatureMouseLeave(event)
	{
		// NB: Change cursor back to default (from pointer) when it leaves.
		this.map.getCanvas().style.cursor = '';
	}
	
	onGeocoderResult(event)
	{
		// TODO: Check for zero results / error condition, if that's possible in this callback. Refer to mapbox docs
		let latlng		= [event.result.center[1], event.result.center[0]];
		let features	= this.getFeaturesAtLatLng(latlng);
		
		if(!features.length)
		{
			// this.map.fire("closeAllPopups");
			return;
		}
		
		let properties	= features[0].properties;
		
		this.showInfo(properties, latlng);
	}
}

App.MODE_DEVELOPER			= "developer";
App.MODE_HOMEOWNER			= "homeowner";

App.MAPBOX_ACCESS_TOKEN		= "pk.eyJ1IjoieWVsbG93c3ViY3JlYXRpdmUiLCJhIjoiY2tnY2hyMm5zMHI1ZzMxa3pyaGJuOXNlcCJ9.q6xJjTH_9XMMVR_HdMJM9Q";
App.MAPBOX_STYLE_URL		= "mapbox://styles/yellowsubcreative/cki03adjj1n4r19mjere40a6v";

App.DEFAULT_MAP_CENTER		= [-3.179090, 51.481583];
App.DEFAULT_MAP_ZOOM		= 12;

App.LAYER_NAME				= "All_Technologies_WGS84";

App.DOCKER_DATA_URL			= "https://api.maptiler.com/tiles/40d914bd-a068-4b36-a43f-a0939ced4795/tiles.json?key=176n38Nio31FxGENgZ55";

App.API2PDF_API_KEY			= "a2e10b0b-3922-4eee-b732-81194c168c20"; 

App.BBOX_WALES				= [ -5.3567, 51.3801, -2.471, 53.4318]; // NB: Bounding box representing Wales"