const $ = jQuery;

export default class Tilemap
{
	constructor(app, map)
	{
		this.app = app;
		this.map = map;
		this.layerIDs = [];
		this._visible = true;
		
		this.update();
		
		map.on("moveend", event => this.onMapMoveEnd(event));
		$("#developer-panel input[name='tilemap']").on("change", event => this.onTilemapTypeChanged(event));
		
		if(this.app.mode == App.MODE_HOMEOWNER)
			this.hide();
	}
	
	get property()
	{
		return $("#developer-panel input[name='tilemap']:checked").attr("data-property");
	}
	
	get colour()
	{
		return $("#developer-panel input[name='tilemap']:checked").attr("data-colour");
	}
	
	// TODO: These switches are growing quite large. It might be best to store filters and layer counts as keyed objects to keep this data together
	
	getPropertyFilter(property, layerIndex)
	{
		switch(property)
		{
			case "LSW_Wat75":
			case "LSW_BUA50":
			case "SSW_Wat75":
			case "SSW_BUA50":
			case "SSH_HyWW":
			
				if(layerIndex == 0)
					return ['!=', property, 'Y'];
				else
					return ['==', property, 'Y'];
					
				break;
			
			case "LSW_WindPr":
			case "LSS_SPA":
			case "SSW_WindPr":
			
				/*if(layerIndex == 0)
					return ["in", property, "mainly outside"];
				else
					return ["in", property, "mainly within"];*/
				
				if(layerIndex == 0)
					return ['in', 'mainly outside', ['get', property]];
				else
					return ['in', 'mainly within', ['get', property]];
			
				return;
			
			case "LSW_Slo15":
			case "SSW_Slo15":
			case "LSW_Tran":
			case "LSS_Tran":
			case "SSW_Tran":
			case "LSW_ExRen":
			case "LSS_ExRen":
			case "SSW_ExRen":
			case "LSW_RoRa":
			case "LSS_RoRa":
			case "SSW_RoRa":
			case "LSW_ATR":
			case "LSS_ATR":
			case "SSW_ATR":
			case "LSW_Water":
			case "LSS_Water":
			case "SSW_Water":
			case "LSW_Wood":
			case "LSS_Wood":
			case "SSW_Wood":
			
				if(layerIndex == 0)
					return ['in', 'does not contain', ['get', property]];
				else
					return ['in', 'contains', ['get', property]];
			
				break;
			
			case "LSW_NATS":
			case "SSW_NATS":
			
				if(layerIndex == 0)
					return ['in', 'not within', ['get', property]];
				else
					return ['in', 'is within', ['get', property]];
			
				break;
			
			case "LSS_OAL":
			case "LSW_FZ":
			case "SSW_FZ":
			case "LSS_FZ":
			case "LSS_FWA":
			
				if(layerIndex == 0)
					return ['in', 'is outside', ['get', property]];
				else
					return ['in', 'is within', ['get', property]];
			
				break;
			
			case "LSW_LMVS":
			case "LSS_LMVS":
			case "SSW_LMVS":
			case "LSW_LMLH":
			case "LSS_LMLH":
			case "SSW_LMLH":
			case "LSW_LMHL":
			case "SSW_LMHL":
			case "LSS_LMHL":
			case "LSW_LMGL":
			case "SSW_LMGL":
			case "LSS_LMGL":
			case "LSW_LMCL":
			case "SSW_LMCL":
			case "LSS_LMCL":
			
				if(layerIndex == 0)
					return ['in', 'not within', ['get', property]];
				else
					return ['in', 'within or partially within', ['get', property]];
			
				break;
			
			case "LSW_Build":
			case "SSW_Build":
			
				return ['in', [
					"contains Buildings",
					"is within 500m from buildings",
					"is more than 500m from buildings",
					"does not contain Buildings"
				][layerIndex - 1], ['get', property]];
				
				break;
			
			case "LSS_Build":
			
				return ['in', [
					"contains Buildings",
					"is within 20m of buildings",
					"is more than 20m from buildings"
				][layerIndex - 1], ['get', property]];
			
				break;
			
			case "LSW_Rank":
			case "SSW_Rank":
			case "MSW_Rank":
			case "LSS_Rank":
			case "RMS_Rank":
			case "GTB_GSRank":
			case "GTB_DHRank":
			case "GTQ_GSRank":
			case "GTQ_DHRank":
			case "SSH_HRank":
			case "LSS_TIRank":
			case "RMS_TIRank":
			case "RMS_AsRank":
			
			case "LSW_WSR":
			case "SSW_WSR":
			
			case "LSS_SIAs":
			
			case "GTB_YielS":
			
				return ['==', property, layerIndex];
				
				break;
			
			// TODO: Add adaption for MSW_WSR
			
			default:
			
				console.warn("Don't know how to filter on property " + property);
				return null;
				
				break;
		}
	}
	
	getLayerCount(property)
	{
		switch(property)
		{
			case "LSW_Wat75":
			case "LSW_BUA50":
			case "SSW_Wat75":
			case "SSW_BUA50":
			case "SSH_HyWW":
			
				return 2;
				
				break;
			
			case "LSW_WindPr":
			case "LSS_SPA":
			case "SSW_WindPr":
			
				return 2;
				
				break;
				
			case "LSW_Slo15":
			case "SSW_Slo15":
			case "LSW_Tran":
			case "LSS_Tran":
			case "SSW_Tran":
			case "LSW_ExRen":
			case "LSS_ExRen":
			case "SSW_ExRen":
			case "LSW_RoRa":
			case "LSS_RoRa":
			case "SSW_RoRa":
			case "LSW_ATR":
			case "LSS_ATR":
			case "SSW_ATR":
			case "LSW_Water":
			case "LSS_Water":
			case "SSW_Water":
			case "LSW_Wood":
			case "LSS_Wood":
			case "SSW_Wood":
			
			case "LSW_NATS":
			case "SSW_NATS":
			
			case "LSS_OAL":
			case "LSW_FZ":
			case "SSW_FZ":
			case "LSS_FZ":
			case "LSS_FWA":
			
			case "LSW_LMVS":
			case "LSS_LMVS":
			case "SSW_LMVS":
			case "LSW_LMLH":
			case "LSS_LMLH":
			case "SSW_LMLH":
			case "LSW_LMHL":
			case "SSW_LMHL":
			case "LSS_LMHL":
			case "LSW_LMGL":
			case "SSW_LMGL":
			case "LSS_LMGL":
			case "LSW_LMCL":
			case "SSW_LMCL":
			case "LSS_LMCL":
			
				return 2;
				
				break;
			
			case "LSW_Build":
			case "SSW_Build":
				
				return 4;
				
				break;
				
			case "LSS_Build":
			
				return 3;
			
				break;
			
			case "LSW_Rank":
			case "SSW_Rank":
			case "MSW_Rank":
			case "LSS_Rank":
			case "RMS_Rank":
			case "GTB_GSRank":
			case "GTB_DHRank":
			case "GTQ_GSRank":
			case "GTQ_DHRank":
			case "SSH_HRank":
			case "LSS_TIRank":
			case "RMS_TIRank":
			case "RMS_AsRank":
			
			case "LSW_WSR":
			case "SSW_WSR":
			
			case "LSS_SIAs":
			
			case "GTB_YielS":
			
				return 5;
				
				break;
			
			// TODO: Add adaption for MSW_WSR
			
			default:
			
				console.warn("Don't know how many layers to use to display property " + property);
				return 0;
				
				break;
		}
	}
	
	getOpacity(val, max)
	{
		if(max > 2)
		{
			let f	= (val - 1) / (max - 1);	// 0.0 - 1.0
			let g	= Math.pow(f, 2);
			let h	= g * 0.35;
			
			return h;
		}
		
		return (val - 1) / max;
	}
	
	hide()
	{
		this._visible = false;
		this.update();
	}
	
	show()
	{
		this._visible = true;
		this.update();
	}
	
	update()
	{
		this.layerIDs.forEach((id) => {
			this.map.removeLayer(id);
		});
		this.layerIDs = [];
		
		if(!this._visible)
			return;
			
		let count = this.getLayerCount(this.property);
		
		for(let i = 1; i <= count; i++)
		{
			let id = "tilemap-level-" + i;
			
			this.map.addLayer({
				id:				id,
				type:			"fill",
				source:			App.LAYER_NAME,
				"source-layer":	App.LAYER_NAME,
				paint: {
					"fill-color":	this.colour,
					"fill-opacity":	this.getOpacity(i, count)
				},
				filter: this.getPropertyFilter(this.property, i)
			}, this.app.getMapFirstSymbolLayerId());
			
			this.layerIDs.push(id);
		}
	}
	
	onMapMoveEnd(event)
	{
		this.update();
	}
	
	onTilemapTypeChanged(event)
	{
		$("#developer-panel label").removeClass("selected");
		$(event.currentTarget).closest("label").addClass("selected");
		
		if($(event.currentTarget).closest("#developer-panel ul").length)
			$(event.currentTarget).closest("#developer-panel ul").prev("label").addClass("selected");
		
		this.update();
		
		$("#developer-panel").trigger("statechange.nuable");
	}
}
