export default class PDF
{
	constructor()
	{
		this.doc = new jspdf.jsPDF({
			orientation: "landscape"
		});
		
		this.margin = {
			x: 10,
			y: 20
		};
		
		this.cursor = {};
		
		this.fontSize				= 9;
		this.lineHeight				= 5;
		this.propertyNameWidth		= 75;
		this.pageWidth				= 297;
		this.pageHeight				= 210;
		
		this.resetCursor();
	}
	
	getRGBFromHex(hex)
	{
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
	
	getCharacterImage()
	{
		let characterImages = [];
		let characterNamesRegex = /(Freya|Erika|Jim|Morgan|Cat-and-Dog)\.png$/i;
		
		for(var key in this.images)
		{
			let img = this.images[key];
			
			if(characterNamesRegex.exec(img.src))
				characterImages.push(img);
		}
		
		if(!("currentCharacterImageIndex" in this))
			this.currentCharacterImageIndex = Math.floor( Math.random() * characterImages.length );
		
		let result = characterImages[this.currentCharacterImageIndex];
		
		this.currentCharacterImageIndex = (this.currentCharacterImageIndex + 1) % characterImages.length;
		
		return result;
	}
	
	resetCursor()
	{
		this.cursor.x = this.margin.x;
		this.cursor.y = this.margin.y;
	}
	
	advanceCursor(y)
	{
		this.cursor.y += y;
		
		if(this.cursor.y >= this.pageHeight - this.margin.y)
			this.startNextPage();
	}
	
	startPage()
	{
		let image		= this.getCharacterImage();
		
		let pageWidth	= 297;
		let pageHeight	= 210;
		let scale		= 0.2;
		
		let w			= image.width * scale;
		let h			= image.height * scale;
		
		let x			= pageWidth - w;
		let y			= pageHeight - h;
		
		this.resetCursor();
		
		this.doc.addImage(image, 'JPEG', x, y, w, h);
	}
	
	startNextPage()
	{
		this.doc.addPage();
		this.startPage();
	}
	
	addLogo()
	{
		let scale		= 0.2;
		let image		= this.images.logo;
		
		let w			= image.width * scale;
		let h			= image.height * scale;
		
		let x			= this.cursor.x;
		let y			= this.cursor.y;
		
		this.doc.addImage(image, x, y, w, h);
		
		this.advanceCursor(h + 20);
	}
	
	addSummary()
	{
		let imageData	= this.scoresCanvas.toDataURL();
		let scale		= 0.2;
		
		// Main logo
		this.addLogo();
		
		this.addCoordinates();
		
		// Score bars
		this.doc.addImage(imageData, this.cursor.x, this.cursor.y, imageData.width * scale, imageData.height * scale);
	}
	
	addCoordinates()
	{
		// Coordinates
		let text	= $("[data-name='coordinates']").text().replace(/[\r\n]/, " ");
		
		let x		= this.cursor.x;
		let y		= this.cursor.y;
		
		let scale	= 2;
		
		this.doc.setFontSize(this.fontSize * scale);
		
		this.doc.text(x, y, text);
		
		this.advanceCursor(this.lineHeight * scale);
	}
	
	addRenewableHeading(text, type)
	{
		let x = this.cursor.x;
		let y = this.cursor.y;
		
		let colorByType = {
			"wind":		"#009982",
			"solar":	"#f39200",
			"geo":		"#e5005b",
			"hydro":	"#009dc5"
		};
		
		let color = colorByType[type];
		let scale = 2;
		
		let rgb = this.getRGBFromHex(color);
		
		this.doc.setFontSize(this.fontSize * scale);
		this.doc.setTextColor(rgb.r, rgb.g, rgb.b);
		
		this.doc.text(x + 20, y, text);
		
		this.advanceCursor(this.lineHeight * scale);
	}
	
	addRenewableDescription(text)
	{
		let lines;
		let margin = this.cursor.x + 20;
		
		this.doc.setTextColor(0, 0, 0);
		this.doc.setFontSize(this.fontSize);
		
		this.cursor.x = this.margin.x;
		
		text	= text.replace(/\s+/g, " ").trim();
		lines	= this.doc.splitTextToSize(text, this.pageWidth - margin * 2);
		
		lines.forEach(line => {
			
			this.doc.text(margin, this.cursor.y, line);
			this.advanceCursor(this.lineHeight);
			
		});
		
		// NB: Add margin below description
		this.advanceCursor(this.lineHeight);
	}
	
	addProperty(name, value)
	{
		let x = this.cursor.x;
		let y = this.cursor.y;
		
		this.doc.setFontSize(this.fontSize);
		this.doc.setTextColor(0, 0, 0);
		
		this.doc.text(x + 20, y, name);
		this.doc.text(x + 20 + this.propertyNameWidth, y, value);
		
		this.advanceCursor(this.lineHeight);
	}
	
	addRenewables($element)
	{
		let containers		= $element.find(".renewable-details");
		
		containers.each((index, container) => {
			
			this.startNextPage();
			
			let text		= $(container).attr("data-heading");
			let type		= $(container).attr("data-renewable-type");
			
			this.addRenewableHeading(text, type);
			
			text			= $(container).find(".description").text();
			
			this.addRenewableDescription(text);
			
			$(container).find(".property").each((index, property) => {
				
				let name		= $(property).find(".property-name > span").text();
				let value		= $(property).find(".property-value").text();
				
				this.addProperty(name, value);
				
			});
			
		});
	}
	
	addDisclaimer()
	{
		let text = $(".disclaimer").text().replace(/\s+/g, " ").trim();
		
		this.startNextPage();
		
		let margin = this.cursor.x + 20;
		
		lines	= this.doc.splitTextToSize(text, this.pageWidth - margin * 2);
		
		lines.forEach(line => {
			
			this.doc.text(margin, this.cursor.y, line);
			this.advanceCursor(this.lineHeight);
			
		})
		
	}
	
	loadImages(callback)
	{
		let self		= this;
		
		let urls		= PDF.IMAGE_URLS;
		let loaded		= 0;
		let total		= (Object.keys(urls)).length;
		
		this.images		= {};
		
		for(var key in urls)
		{
			let img = document.createElement("img");
			
			this.images[key] = img;
			
			img.onload = function() {
				
				if(++loaded == total)
					callback();
				
			}
			
			img.src = urls[key];
		}
	}
	
	download(element, filename, callback)
	{
		/*
		First page is a summary
		Second page is wind
		Page break for solar
		Page break for geo
		Page break for hydro
		Optional last page
		*/
		
		html2canvas( $(element).find("div.scores")[0] ).then((canvas) => {
			
			this.scoresCanvas = canvas;
			
			this.loadImages(() => {
				
				const $element		= $(element);
				const doc			= this.doc;
				
				this.startPage();
				
				this.addSummary();
				this.addRenewables($element);
				
				this.addDisclaimer();
				
				doc.save("Your Nuable Report.pdf");
				
				if(callback)
					callback();
				
			});
			
		});
		
		
	}
}

PDF.IMAGE_URLS = {
	logo:			"/images/nuable-pdf-logo.png",
	freya:			"/images/freya.png",
	erika:			"/images/erika.png",
	jim:			"/images/jim.png",
	morgan:			"/images/morgan.png",
	"cat-and-dog":	"/images/cat-and-dog.png"
};