import PDF from "./PDF.js";
import Scores from "./Scores.js";

export default class UI
{
	constructor(app)
	{
		let self = this;
		
		this.app = app;
		
		this.$closeMenu			= $("#closeMenu");
		this.$detailView		= $("#detail-info");
		this.$info				= $("#info");
		this.$map				= $("#map");
		
		// TODO: This constructor is getting messy. Please delegate some of these closures to class methods
		
		// TODO: Repeated, DRY up please
		$("[data-required-mode][data-required-mode!='developer']").hide();
		$("[data-required-mode][data-required-mode='developer']").show();
		
		if(app.getQueryStringParamValue("mode") == "homeowner")
		{
			$("input[name='homeowner']").prop("checked", true);
			this.onModeChanged({
				currentTarget: $("input[name='homeowner']")[0]
			});
		}
		
		$("#homeowner-panel").hide();
		
		$(document.body).on("click", ".download-pdf", event => this.onDownloadPDF(event));
		$(document.body).on("click", ".open-detail-info", event => this.onOpenDetailInfo(event));
		
		$("input[name='homeowner']").on("change", event => this.onModeChanged(event));
		
		$("#developer-panel").on("change", "input[type='radio']", event => this.onTileMapChanged(event));
		
		$(".tabs").tabs({
			activate: function(event, ui) { 
				$(this).trigger("statechange.nuable"); 
			}
		});
		
		// Populate homeowner rank tooltips
		$('#modal').on('show.bs.modal', function (event) {
			
			let button		= $(event.relatedTarget); // Button that triggered the modal
			let property	= $(button).prev(".score-bar").find("[data-property]").attr("data-property");
			
			let title		= self.app.detail.getPropertyFriendlyName(property);
			let content		= $("#tooltips [data-properties~='" + property + "']").html();
			
			// var recipient = button.data('whatever') // Extract info from data-* attributes
			// If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
			// Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
			let modal		= $(this);
			
			modal.find('.modal-title').text(title);
			modal.find('.modal-body').html(content);
			
		});

		// TODO: Listen for resize event and re-trigger this
		$(window).on("resize", event => this.onResize(event));
		this.onResize();
		
		$("#detail-info > .toggle").on("click", () => {
		
			$("#detail-info")
				.toggleClass("open");
			
			if($("#detail-info").hasClass("open"))
				$("#renewables").fadeOut();
			else
				$("#renewables").fadeIn();
			
			setTimeout(() => {
				this.app.map.resize();
			}, 500);
		
		});
		
		$("#renewables [data-property]").on("click", event => {
			
			let property = $(event.currentTarget).attr("data-property");
			
			$("#developer-panel input[data-property='" + property + "']").prop("checked", true).trigger("change");
			
		});
		
		$(window).on("load", (event) => {
			$("#expandcollapse").css({height: $("#info").height() + "px"})
			
			// NB: Call resize again to allow elements with image assets to size properly (eg renewables)
			this.onResize(event);
		});
		
		$(document.body).on("click", "[data-href]", (event) => {
			window.open( $(event.currentTarget).attr("data-href"), "_BLANK" );
		});
	}
	
	get isPortrait()
	{
		return window.matchMedia("screen and (orientation: portrait)").matches;
	}
	
	get isMobile()
	{
		return window.matchMedia("screen and (orientation: portrait) and (max-width: 768px)").matches;
	}
	
	onResize(event)
	{
		let $renewables = $("#renewables");
		
		$("#info, #detail > .inner").css({
			"max-height":	(window.innerHeight - $("header").outerHeight()) + "px"
		});
		
		// NB: I've hidden these in mobile view for now until I can get them to play nicely
		/*if(this.isPortrait)
		{
			let top = window.innerHeight - ($renewables.height() + $("header").height());
			
			$renewables.css({
				position: "absolute",
				top: top + "px"
			});
		}
		else
		{
			// NB: Remove inline styles
			$renewables.css({
				position: null,
				top: null
			});
		}*/
	}
	
	onTileMapChanged(event)
	{
		if(!this.isMobile)
			return;
		
		$("#wrapper").animate({
			scrollTop: $("#map").offset().top
		}, 500);
	}
	
	// NB: Consider moving this to the Detail class now that it's only accessible there
	onDownloadPDF(event)
	{
		let pdf = new PDF();
		let $button = $(event.currentTarget);
		let originalText = $button.text();
		
		$button.prop("disabled", true).text("Please wait...");
		
		pdf.download($("#detail-info .inner")[0], "test.pdf", () => {
			$button.prop("disabled", false).text(originalText);
		});
		
		event.preventDefault();
		return false;
	}
	
	onOpenDetailInfo(event)
	{
		if(this.isPortrait)
		{
			$("#wrapper").animate({
				scrollTop: $("#detail-info .inner").offset().top
			}, 500);
		}
		else
		{
			this.$detailView.addClass("open");
			
			setTimeout(() => {
				this.app.map.resize();
			}, 500);
		}
		
		$("#renewables").fadeOut();
	}
	
	onModeChanged(event)
	{
		if($(event.currentTarget).prop("checked"))
		{
			// NB: Checked state represents "homeowner"
			$("#developer-panel").fadeOut(() => {
				$("#homeowner-panel").fadeIn();
				window.nuable.map.resize();
			});
			
			$("#renewables a").css({cursor: "default"});
			
			$("[data-required-mode][data-required-mode!='homeowner']").hide();
			$("[data-required-mode][data-required-mode='homeowner']").show();
			
			if(this.app.tilemap)
				this.app.tilemap.hide();
			
			Scores.setSourceProperties(Scores.MAP_HOMEOWNER);
		}
		else
		{
			// NB: Unchecked state represents "developer"
			$("#homeowner-panel").fadeOut(() => {
				$("#developer-panel").fadeIn();
				window.nuable.map.resize();
			});
			
			$("#renewables a").css({cursor: "pointer"});
			
			$("[data-required-mode][data-required-mode!='developer']").hide();
			$("[data-required-mode][data-required-mode='developer']").show();
			
			if(this.app.tilemap)
				this.app.tilemap.show();
				
			Scores.setSourceProperties(Scores.MAP_DEVELOPER);
		}
		
		this.$info.trigger("modechange.nuable");
		this.$info.trigger("statechange.nuable");
	}
}