export default class ZoomControl
{
	constructor(map)
	{
		this.map = map;
		
		this.$element = $(`
			<div class="nuable-custom-zoom-control mapboxgl-ctrl">
				<div>
					<button data-zoom="out" type="button">-</button>
					<button data-zoom="in" type="button">+</button>
				</div>
				<div>
					Zoom
				</div>
			</div>
		`);
		
		this.$element.find("button[data-zoom]").on("click", event => this.onZoom(event));
	}
	
	onZoom(event)
	{
		let $button	= $(event.currentTarget);
		let type	= $button.attr("data-zoom");
		let level	= this.map.getZoom();
		
		if(type == "in")
			level++;
		else if(type == "out")
			level--;
		else
			throw new Error("Unexpected state");
		
		this.map.flyTo({
			zoom: level
		});
	}
}