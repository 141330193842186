// TODO: Listen for popup close

export default class State
{
	constructor(app)
	{
		this.app = app;
		this.load();
		
		$(document.body).on("statechange.nuable", event => this.onChange(event));
	}
	
	get()
	{
		let center		= this.app.map.getCenter();
		let popup		= this.app.popup;
		
		let result = {
			mode: this.app.mode,
			center:	{
				lat:	center.lat.toFixed(3),
				lng:	center.lng.toFixed(3)
			},
			zoom:		this.app.map.getZoom().toFixed(2),
			detail: {
				tab:	$("#detail-info .tabs").tabs("option", "active")
			}
		};
		
		if(this.app.detail.isExpanded)
			result.detail.expanded = true;
		
		if(this.app.selectedGridId)
			result.selectedGridId = this.app.selectedGridId;
			
		switch(this.app.mode)
		{
			case App.MODE_HOMEOWNER:
			
				let $address	= $("input.mapboxgl-ctrl-geocoder--input");
			
				if($address.val().length)
					result.address = $address.val();
			
				break;
			
			case App.MODE_DEVELOPER:
				
				result.tilemap	= $("input[name='tilemap']:checked").attr("data-property");
				
				break;
		}
		
		return result;
	}
	
	set(state)
	{
		if("center" in state)
			this.app.map.setCenter([state.center.lng, state.center.lat]);
		
		if("zoom" in state)
			this.app.map.setZoom(state.zoom);
		
		if("mode" in state && state.mode == App.MODE_HOMEOWNER)
			$("input[name='homeowner']")
				.prop("checked", true)
				.trigger("change");
		
		if("tilemap" in state)
		{
			$("input[name='tilemap'][data-property='" + state.tilemap + "']")
				.prop("checked", true)
				.trigger("change");
		}
		
		if("address" in state)
			$("input.mapboxgl-ctrl-geocoder--input").val(state.address);
		
		if("selectedGridId" in state)
			this.app.map.once("idle", event => {
				
				let feature		= this.app.getFeatureByGridId(state.selectedGridId);
				
				if(!feature)
				{
					console.warn("Error finding feature specified in state");
					return;
				}
				
				let properties	= feature.properties;
				let point		= this.app.getFeatureCentroid(feature);
				
				this.app.showInfo(properties, point);
				
			});
		
		if("detail" in state)
		{
			if(state.detail.tab)
				$("#detail-info .tabs").tabs("option", "active", state.detail.tab);
			
			if(state.detail.expanded)
			{
				this.app.detail.$element.addClass("open");
				$("#renewables").fadeOut();
			}
		}
	}
	
	load()
	{
		if(!window.location.hash || !window.location.hash.length)
			return;
		
		let data;
		
		try{
			
			let b64			= window.location.hash.replace(/^#/, "");
			let string		= atob(b64);
			
			data		= JSON.parse(string);
			
		}catch(e) {
			console.warn("Failed to parse state data from URL");
			return;
		}
		
		this.set(data);
		
		$("#preloader").fadeOut(500, function() { $(this).remove(); });
	}
	
	save()
	{
		let data		= this.get();
		let string		= JSON.stringify(data);
		// let encoder		= new TextEncoder();
		// let bytes		= encoder.encode(string);
		// let compressed	= pako.deflate(bytes);
		let b64			= btoa(string);
		
		window.location.hash = b64;
	}
	
	onChange()
	{
		this.save();
	}
}