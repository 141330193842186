import Scores from "./Scores.js";

const $ = jQuery;

export default class Detail
{
	constructor()
	{
		this.$element = $("#detail-info");
		this.scores = new Scores(this.$element.find(".scores"));
		
		this.$element.on("click", "dt", event => this.onExpandTooltip(event));
	}
	
	get isExpanded()
	{
		return this.$element.hasClass("open");
	}
	
	getPropertyCategory(property)
	{
		let m = /(\w)_/.exec(property);
		
		if(!m || m.length < 2)
			return;
		
		switch(m[1])
		{
			case "W":
				return "wind";
				break;
			
			case "S":
				return "solar";
				break;
			
			case "B":
			case "Q":
				return "geo";
				break;
			
			case "H":
				return "hydro";
				break;
		}
	}
	
	getPropertyFriendlyName(property)
	{
		return {
			"LSW_Rank":	    "Overall Rank (Large Scale)",
			"SSW_Rank":	    "Overall Rank (Small Scale)",
			"MSW_Rank":	    "Overall Rank (Micro Scale)",
			"LSS_Rank":	    "Overall Rank (Large Scale)",
			"RMS_Rank":	    "Overall Rank (Roof mounted)",
			"GTB_GSRank":	"Ground Source Heat Rank (Bedrock)",
			"GTB_DHRank":	"District Heat Network Rank (Bedrock)",
			"GTQ_GSRank":	"Ground Source Heat Rank (Quarternary)",
			"GTQ_DHRank":	"District Heat Network Rank (Quarternary)",
			"SSH_HRank":	"Overall Rank (Small Scale)",
			"LSW_WSR":	    "Windspeed at 100m (Large Scale)",
			"LSW_Wat75":	"Waterbodies (>75% Water) (Large Scale)",
			"LSW_BUA50":	"Built Up Areas (>50% Buildings) (Large Scale)",
			"LSW_WindPr":	"Wind Priority Area (Large Scale)",
			"LSW_WindPo":	"Wind Power Density (Large Scale)",
			"LSW_Slo15":	"Slope above 15° (Large Scale)",
			"LSW_Tran":	    "Transmission Lines (Large Scale)",
			"LSW_Subs":	    "Substations (Large Scale)",
			"LSW_ExRen":	"Existing Renewables (Large Scale)",
			"LSW_RoRa":	    "Roads and Rail (Large Scale)",
			"LSW_ATR":	    "Active Travel Routes (Large Scale)",
			"LSW_Air":	    "Operational Airports (Large Scale)",
			"LSW_NATS":	    "NATS Safeguarding (Large Scale)",
			"LSW_Build":	"Buildings (Large Scale)",
			"LSW_Water":	"Watercourses and Waterbodies (Large Scale)",
			"LSW_Wood":	    "Woodland (Large Scale)",
			"LSW_Land":	    "Landscape Designation (Large Scale)",
			"LSW_Biod":	    "International and National Biodiversity (Large Scale)",
			"LSW_BiodLo":	"Other Biodiversity Site (Large Scale)",
			"LSW_CH":	    "Cultural Heritage Designation (Large Scale)",
			"LSW_FZ":	    "Flood Zone (Large Scale)",
			"LSW_LMVS":	    "LANDMAP Visual Sensory (Large Scale)",
			"LSW_LMLH":	    "LANDMAP Landscape Habitats (Large Scale)",
			"LSW_LMHL":	    "LANDMAP Historic Landscape (Large Scale)",
			"LSW_LMGL":	    "LANDMAP Geological Landscape (Large Scale)",
			"LSW_LMCL":	    "LANDMAP Cultural Landscape (Large Scale)",
			"SSW_WSR":	    "Windspeed at 50m (Small Scale)",
			"SSW_Wat75":	"Waterbodies (>75% Water) (Small Scale)",
			"SSW_BUA50":	"Built Up Areas (>50% Buildings) (Small Scale)",
			"SSW_WindPr":	"Wind Priority Area (Small Scale)",
			"SSW_WindPo":	"Wind Power Density (Small Scale)",
			"SSW_Slo15":	"Slope above 15° (Small Scale)",
			"SSW_Tran":	    "Transmission Lines (Small Scale)",
			"SSW_Subs":	    "Substations (Small Scale)",
			"SSW_ExRen":	"Existing Renewables (Small Scale)",
			"SSW_RoRa":	    "Roads and Rail (Small Scale)",
			"SSW_ATR":	    "Active Travel Routes (Small Scale)",
			"SSW_Air":	    "Operational Airports (Small Scale)",
			"SSW_NATS":	    "NATS Safeguarding (Small Scale)",
			"SSW_Build":	"Buildings (Small Scale)",
			"SSW_Water":	"Watercourses and Waterbodies (Small Scale)",
			"SSW_Wood":	    "Woodland (Small Scale)",
			"SSW_Land":	    "Landscape Designation (Small Scale)",
			"SSW_Biod":	    "International and National Biodiversity (Small Scale)",
			"SSW_BiodLo":	"Other Biodiversity Site (Small Scale)",
			"SSW_CH":	    "Cultural Heritage Designation (Small Scale)",
			"SSW_FZ":	    "Flood Zone (Small Scale)",
			"SSW_LMVS":	    "LANDMAP Visual Sensory (Small Scale)",
			"SSW_LMLH":	    "LANDMAP Landscape Habitats (Small Scale)",
			"SSW_LMHL":	    "LANDMAP Historic Landscape (Small Scale)",
			"SSW_LMGL":	    "LANDMAP Geological Landscape (Small Scale)",
			"SSW_LMCL":	    "LANDMAP Cultural Landscape (Small Scale)",
			"MSW_WSR":	    "Windspeed at 10m (Micro Scale)",
			"MSW_WindPo":	"Wind Power Density (Micro Scale)",
			"MSW_Cons":	    "Conservation Areas (Micro Scale)",
			"LSS_GTI":	    "Global Tilted Irradiation (Large Scale)",
			"LSS_TIRank":	"Global Tilted Irradiation Rank (Large Scale)",
			"LSS_Slo":	    "Slope (Large Scale)",
			"LSS_Asp":	    "Aspect (Large Scale)",
			"LSS_SlAs":	    "Slope and Aspect (Large Scale)",
			"LSS_PVOut":	"Solar PVOut (Large Scale)",
			"LSS_SPA":	    "Solar Priority Areas (Large Scale)",
			"LSS_Tran":	    "Transmission Lines (Large Scale)",
			"LSS_Subs":	    "Substations (Large Scale)",
			"LSS_ExRen":	"Existing Renewables (Large Scale)",
			"LSS_RoRa":	    "Roads and Rail (Large Scale)",
			"LSS_ATR":	    "Active Travel Routes (Large Scale)",
			"LSS_Air":	    "Operational Airports (Large Scale)",
			"LSS_Build":	"Buildings (Large Scale)",
			"LSS_Water":	"Watercourses and Waterbodies (Large Scale)",
			"LSS_Wood":	    "Woodland (Large Scale)",
			"LSS_Land":	    "Landscape Designations (Large Scale)",
			"LSS_Biod":	    "International and National Biodiversity (Large Scale)",
			"LSS_BiodLo":	"Other Biodiversity Sites (Large Scale)",
			"LSS_CH":	    "Cultural Heritage Designations (Large Scale)",
			"LSS_FZ":	    "Flood Zone (Large Scale)",
			"LSS_FWA":	    "Flood Warning Area (Large Scale)",
			"LSS_LMVS":	    "LANDMAP Visual Sensory (Large Scale)",
			"LSS_LMLH":	    "LANDMAP Landscape Habitats (Large Scale)",
			"LSS_LMHL":	    "LANDMAP Historic Landscape (Large Scale)",
			"LSS_LMGL":	    "LANDMAP Geological Landscape (Large Scale)",
			"LSS_LMCL":	    "LANDMAP Cultural Landscape (Large Scale)",
			"LSS_OAL":	    "Open Access Land (Large Scale)",
			"LSS_ALC":	    "Agricultural Land Classification (Large Scale)",
			"RMS_GTI":	    "Global Tilted Irradiation (Roof Mounted)",
			"RMS_TIRank":	"Global Tilted Irradiation Rank (Roof Mounted)",
			"RMS_Asp":	    "Aspect (Roof Mounted)",
			"RMS_AsRank":	"Aspect Rank (Roof Mounted)",
			"RMS_PVOut":	"Solar PVOut (Roof Mounted)",
			"RMS_Slo":	    "Slope (Roof Mounted)",
			"RMS_Cons":	    "Conservation Area (Roof Mounted)",
			"GTB_GeoGr":	"Geological Group (Bedrock)",
			"GTB_Aqua":	    "Aquifer Class (Bedrock)",
			"GTB_Prod":	    "Productivity (Bedrock)",
			"GTB_TemMax":	"Temperature Max (Bedrock)",
			"GTB_TemMin":	"Temperature Min (Bedrock)",
			"GTB_TemMea":	"Temperature Mean (Bedrock)",
			"GTB_Bore":	    "Boreholes (Bedrock)",
			"GTB_FlowM":	"Flow Mechanism (Bedrock)",
			"GTB_FlowS":	"Flow Sum (Bedrock)",
			"GTB_Yield":	"Yield (Bedrock)",
			"GTB_AquaS":	"Score Aquifer (Bedrock)",
			"GTB_ProdS":	"Score Productivity (Bedrock)",
			"GTB_TempS":	"Score Temperature (Bedrock)",
			"GTB_YielS":	"Score Yield (Bedrock)",
			"GTB_GSHP":	    "Ground Source Heat Potential (Bedrock)",
			"GTB_DHNP":	    "District Heat Network Potential (Bedrock)",
			"GTB_GSHS":	    "Ground Source Heat Score (Bedrock)",
			"GTB_DHNS":	    "District Heat Network Score (Bedrock)",
			"GTQ_GeoGr":	"Geological Group (Quarternary)",
			"GTQ_Class":	"Aquifer Class (Quarternary)",
			"GTQ_Bore":	    "Productivity (Quarternary)",
			"GTQ_TemMax":	"Temperature Max (Quarternary)",
			"GTQ_TemMin":	"Temperature Min (Quarternary)",
			"GTQ_TemMea":	"Temperature Mean (Quarternary)",
			"GTQ_FlowM":	"Boreholes (Quarternary)",
			"GTQ_FlowS":	"Flow Mechanism (Quarternary)",
			"GTQ_Prod":	    "Flow Sum (Quarternary)",
			"GTQ_Yield":	"Yield (Quarternary)",
			"GTQ_AquaS":	"Score Aquifer (Quarternary)",
			"GTQ_ProdS":	"Score Productivity (Quarternary)",
			"GTQ_TempS":	"Score Temperature (Quarternary)",
			"GTQ_YielS":	"Score Yield (Quarternary)",
			"GTQ_GSHP":	    "Ground Source Heat Potential (Quarternary)",
			"GTQ_DHNP":	    "District Heat Network Potential (Quarternary)",
			"GTQ_GSHS":	    "Ground Source Heat Score (Quarternary)",
			"GTQ_DHNS":	    "District Heat Network Score (Quarternary)",
			"SSH_HyJust":	"Hydro Ranking Justification",
			"SSH_HyPow":	"Hydro Power Rating",
			"SSH_HyWW":		"Hydro Win Win Opportunities"
		}[property];
	}
	
	getDegreesMinutesAndSeconds(coordinate)
	{
		let absolute = Math.abs(coordinate);
		let degrees = Math.floor(absolute);
		let minutesNotTruncated = (absolute - degrees) * 60;
		let minutes = Math.floor(minutesNotTruncated);
		let seconds = Math.floor((minutesNotTruncated - minutes) * 60);

		return degrees + "° " + minutes + "' " + seconds + "\"";
	}
	
	getFormattedCoordinates(lat, lng)
	{
		let latitude = this.getDegreesMinutesAndSeconds(lat);
		let latitudeCardinal = lat >= 0 ? "N" : "S";

		let longitude = this.getDegreesMinutesAndSeconds(lng);
		let longitudeCardinal = lng >= 0 ? "E" : "W";

		return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
	}
	
	getGridReference(lat, lng)
	{
		let wgs84 = new GT_WGS84();
		wgs84.setDegrees(lat, lng);
		
		let osgb = wgs84.getOSGB();
		return osgb.getGridRef(3);
	}
	
	getTooltip(property)
	{
		let nodes = $("#tooltips [data-properties~='" + property + "']");
		
		if(!nodes.length)
			return null;
		
		return $(nodes[0]).clone();
	}
	
	isFieldOmitted(name)
	{
		let result = false;
		
		Detail.OMITTED_FIELDS.forEach(regex => {
			
			if(regex.exec(name))
			{
				result = true;
				return false;	// NB: Stop iteration
			}
			
		});
		
		return result;
	}
	
	populate(properties, latlng)
	{
		// Scores
		this.scores.populate(properties);
		
		// Properties
		this.$element.find("dl").html("");
		
		for(let name in properties)
		{
			if(this.isFieldOmitted(name))
				continue;
			
			let className = this.getPropertyCategory(name);
			
			let $div = $("<div></div>");
			$div.addClass("property");
			
			let $span = $("<span></span>");
			$span.text(this.getPropertyFriendlyName(name))
			
			let $dt = $("<dt></dt>");
			$dt.attr("data-property", name);
			$dt.addClass("property-name");
			
			let $dd = $("<dd></dd>");
			$dd.text(properties[name]);
			$dd.attr("data-property", name);
			$dd.addClass("property-value");
			
			let $dl = this.$element.find(`.${className} dl`);
			
			let $tooltip = this.getTooltip(name);
			
			$dt.append($span);
			
			if($tooltip)
				$dt.append($tooltip);
			
			$div.append($dt);
			$div.append($dd);
			
			$dl.append($div);
		}
		
		this.$element.find(".tooltip").closest("dt").addClass("has-tooltip");
		
		// Coordinates
		let coords = this.getFormattedCoordinates(latlng[0], latlng[1]);
		this.$element.find("[data-name='coordinates']").text(coords);
		
		// Grid reference
		let osgb = this.getGridReference(latlng[0], latlng[1]);
		this.$element.find("[data-name='grid-reference']").text(osgb);
	}
	
	onExpandTooltip(event)
	{
		let $dt = $(event.currentTarget);
		
		if($dt.find(".tooltip.expanded").length)
			$dt.find(".tooltip").removeClass("expanded");
		else
		{
			this.$element.find(".tooltip.expanded").removeClass("expanded");
			$dt.find(".tooltip").addClass("expanded");
		}
	}
}

Detail.OMITTED_FIELDS = [
	/Wat75$/i,
	/BUA50$/i,
	/TIRank$/i,
	/SIAs$/i,
	/Asp$/i,
	/AsRank$/i,
	/Slo$/i,
	/HyWW$/i
];