const $ = jQuery;

export default class Intro
{
	constructor()
	{
		this.steps = [{
				id: "welcome",
				el: document,
				templateData: {
					content: 'Welcome to Nuable!'
				}
			}, {
				id: "mode-switch",
				el: '#mode-switch-container',
				templateData: {
					content: 
						`<p>
							Switch between portals for relevant scores.
						</p>
						<ul>
							<li>Access the <strong><i class="fas fa-industry"></i> Developer portal</strong> for panoramic energy potential.</li>
							<li>Access the <strong><i class="fas fa-home"></i> Homeowner portal</strong> for scores specific to your postcode.</li>
						</ul>`
				}
			}, {
				id: "enter-address",
				el: '.mapboxgl-ctrl-geocoder',
				templateData: {
					content: 'Use the address field to search at a specific location.'
				}
			}, {
				id: "click-map",
				el: "#map",
				templateData: {
					content: 'Click any tile on the map for a detailed breakdown of renewables within the area.'
				}
			}
		];
		
		$.ptJs({
		    autoStart: true,
		    templateData: {
		        title: 'Welcome to Nuable'
		    },
			position: {
				relocate: true
			},
		    steps: this.steps
		});
		
		$(document.body).on("modechange.nuable", (event) => this.onModeChanged(event));
		$(document.body).on("popupopen.nuable", (event) => this.onPopupOpen(event));
	}
	
	getStepIndexById(id)
	{
		for(let i = 0; i < this.steps.length; i++)
			if(this.steps[i].id == id)
				return i;
	}
	
	onModeChanged(event)
	{
		// TODO: If on the "Switch between portals" stage, advance
		let state = $.ptJs("info");
		
		if(!state)
			return;
		
		if(state.index == this.getStepIndexById("mode-switch"))
		{
			// NB: Allow a slight delay for the menu to resize, so we open in the correct place on the next step
			setTimeout(() => {
				$.ptJs("next");
			}, 500);
		}
	}
	
	onPopupOpen(event)
	{
		// NB: This is redundant... No such step
		
		// TODO: If on the "click the map" stage, advance
		let state = $.ptJs("info");
		
		if(!state)
			return;
		
		if(state.index == this.getStepIndexById("click-map"))
			$.ptJs('end');
	}
}