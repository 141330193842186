import Panel from "./Panel.js";
import Scores from "./Scores.js";

const $ = jQuery;

export default class Popup extends Panel
{
	constructor()
	{
		super();
		
		this.$element = $(Popup.TEMPLATE_HTML);
		this.scores = new Scores(this.$element.find(".scores"));
	}
	
	get isOpen()
	{
		return this.$element.closest("body").length > 0;
	}
	
	get anchor()
	{
		return this._anchor;
	}
	
	open(map, latlng)
	{
		let popup	= new mapboxgl.Popup();
		
		this._popup = popup;
		this._anchor = {
			lat: latlng[0],
			lng: latlng[1]
		};
		
		popup.setLngLat([latlng[1], latlng[0]])
			.setDOMContent(this.$element[0])
			.addTo(map);
		
		this.$element.trigger("statechange.nuable");
		this.$element.trigger("popupopen.nuable");
		
		popup.on("close", event => {
			this.$element.trigger("statechange.nuable");
			this.$element.trigger("popupclose.nuable");
		});
	}
	
	close()
	{
		this._popup.remove();
	}
	
	populate(properties)
	{
		super.populate(properties);
		
		this.scores.populate(properties);
	}
}

Popup.TEMPLATE_HTML = jQuery("#popup-container").html();
jQuery("#popup-container").remove();

