const $ = jQuery;

export default class Panel
{
	populate(properties)
	{
		if(!("$element" in this))
			throw new Error("Panel has no defined element");
		
		// NB: Add text to named elements
		this.$element.find("[data-name]").each((index, el) => {
			
			let name	= $(el).attr("data-name");
			
			if(!(name in properties))
			{
				console.warn(`Property "${name}" not found in properties`);
				return; // NB: Continue iterating
			}
			
			$(el).text(properties[name]);
			
		});
		
		// NB: Set up PDF link, if we have an element for that
		if(this.$element.is(":has(a.nuable-download-pdf)"))
		{
			if(!("GRID_ID" in properties))
				throw new Error("No GRID_ID found in properties for download link");
				
			let url = "https://v2018.api2pdf.com/chrome/url?url=http://map.nuable.co.uk/output/" + properties.GRID_ID + "&apikey=" + App.API2PDF_API_KEY;
			
			this.$element
				.find("a.nuable-download-pdf")
				.attr("href", url);
		}
	}
}