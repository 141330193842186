const $ = jQuery;

export default class MainMenu
{
	constructor()
	{
		$.ajax(MainMenu.REST_ENDPOINT_URL, {
			success: (response, status, xhr) => this.onRestResponse(response, status, xhr)
		})
	}
	
	onRestResponse(response, status, xhr)
	{
		let $html = $(response.html);
		let hamburger = $html.filter("#nuable-hamburger");
		let overlay = $html.filter("#overlay");
		
		$("header").append(hamburger);
		$("body").append(overlay);
		
		let $link = $("<link rel='stylesheet'/>");
		$link.attr("href", response.style);
		
		let $script = $("<script></script>");
		$script.attr("src", response.script);
		
		$(document.body).append($link);
		$(document.body).append($script);
	}
}

MainMenu.REST_ENDPOINT_URL		= "https://nuable.co.uk/wp-json/nuable/main-menu";